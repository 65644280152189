<template>
    <v-card elevation="3" class="d-flex flex-column" v-if="product" height="98%">
        <div @click="showProductDetail(product._id)" class="mb-3 mt-1 link">
            <video-player v-if="isContentPresent && product.images[0]?.mimetype.startsWith('video/')"
                :src="product.images[0]?.path" :height="$vuetify.breakpoint.smAndUp ? '200px' : '160px'"></video-player>
            <v-img contain v-if="isContentPresent && product.images[0]?.mimetype.startsWith('image/')"
                class="white--text align-end" :height="$vuetify.breakpoint.smAndUp ? '200px' : '160px'"
                :src="imagePath(product.images[0]?.path)"></v-img>
            <v-img contain v-if="!isContentPresent" class="white--text align-end"
                :height="$vuetify.breakpoint.smAndUp ? '200px' : '160px'" :src="defaultProductImage"></v-img>
        </div>
        <div>
            <label class="justify-center title-font link" @click="showProductDetail(product._id)">{{ product.title
                }}</label>
            <label v-if="product.brand && $vuetify.breakpoint.smAndUp" class="justify-center subtitle">{{
                product.brand.displayName }}</label>
        </div>
        <v-spacer></v-spacer>
        <v-card-text class="text--primary">
            <div v-if="product.stock > 0 || $store.getters.vendor.negativeStock || (product.bType == this.packageModule.bType.ONE_TIME || product.bType == this.packageModule.bType.FUTURE_BUY || product.type == 'BUNDLE')"
                wrap>
                <div class="flex-display price-amount justify-center" xs12 sm7 md12>
                    <span v-if="product.offerprice > 0" class="price-amount ml-n2">₹{{ product.offerprice }}/-</span>
                    <span
                        :class="product.offerprice == null || product.offerprice == 0 ? 'price-amount ' : 'strike-through text--secondary'"
                        class="ml-2">₹{{ product.price }}/-</span>
                </div>
                <div :class="$vuetify.breakpoint.xsOnly ? 'mt-1' : 'mt-5'" class="flex-display text-center" xs12 sm5
                    md12>
                    <add-to-cart-btn v-model="product"></add-to-cart-btn>
                </div>
            </div>
            <div class="pb-2 text-center" column v-else>
                <span class="stockOut">Out Of Stock</span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import AddToCartBtn from '@/webstoreComponents/AddToCartBtn.vue';
export default {
    components: {
        AddToCartBtn,
    },
    props: {
        item: Object
    },
    computed: {
        product() {
            return this.item;
        },
        isContentPresent() {
            return this.product.images && this.product.images.length > 0 && this.product.images[0] != null
        }
    }
};
</script>

<style scoped>
.title-font {
    font-size: 1rem !important;
    font-weight: 500 !important;
    padding: 2px 16px !important;
    display: flex !important;
    word-wrap: break-all !important;
    line-height: 1.25rem !important;
}

.subtitle {
    font-size: 13px !important;
    font-weight: 400 !important;
    padding: 0 16px !important;
    padding-top: 10px !important;
    padding-bottom: 2px;
    display: flex !important;
    word-wrap: break-all !important;
    line-height: 1.25rem !important;
    color: gray;
}

.link:hover {
    cursor: pointer;
}

.flex-display {
    display: flex;
    justify-content: center
}

@media screen and (max-width: 600px) {
    .subtitle {
        font-size: 10px !important;
        font-weight: 400 !important;
        padding: 0 16px !important;
        padding-top: 10px !important;
        padding-bottom: 2px;
        display: flex !important;
        word-wrap: breadk-all !important;
        line-height: 1.25rem !important;
        color: gray;
    }
}

.strike-through {
    text-decoration: line-through;
    text-decoration-color: red;
}

.price-amount {
    font-size: 0.9rem;
    font-weight: 550;

}
</style>
