<template>
    <v-flex style="display:inline">
        <v-btn @click="toggleDialog()" :style="theme">Add Payment Details</v-btn>
        <app-dialog :show="showDialog" title="Payment Details" @close="toggleDialog()">
            <v-layout column>
                <v-flex text-right sm6 xs12>
                    <input-date label="Payment Date" v-model="convertedDate"></input-date>
                </v-flex>
                <v-layout>
                    <v-flex xs6>
                        <row-item label="Order No" :value="transaction.refNo"></row-item>
                    </v-flex>
                    <v-flex xs6 text-right>
                        <row-item label="Payble Amount" :value="'Rs. ' + payableAmount + '/-'"></row-item>
                    </v-flex>
                </v-layout>
                <v-flex text-center>
                    <h3 :style="themeInverted">Mode of payment</h3>
                    <payment-mode @update="updateMode" :mode="mode" :showAsBlock="true"
                        :alignCenter="true"></payment-mode>
                </v-flex>
                <v-flex text-center>
                    <h3 :style="themeInverted">Amount</h3>
                    <v-flex xs12>
                        <v-btn-toggle v-model="defaultAmountOption">
                            <v-btn small outlined :style="txAmount == payableAmount ? theme : themeInverted"
                                @click="txAmount = payableAmount">
                                Full Payment
                            </v-btn>
                            <v-btn small outlined :style="txAmount == 0 ? theme : themeInverted" @click="txAmount = 0">
                                Partial Payment
                            </v-btn>
                        </v-btn-toggle>
                    </v-flex>
                    <v-text-field :readonly="defaultAmountOption == 0" :max="payableAmount" @blur="maxValue($event)"
                        class="centered-input" v-model.number="txAmount" label="Enter Amount"></v-text-field>
                    <v-text-field v-model="narration" placeholder="Enter narration"
                        @input="stopAutoNarration = true"></v-text-field>
                    <label hidden>{{ autoGeneratedNarration }}</label>
                </v-flex>
                <v-flex text-right>
                    <v-btn @click="addPaymentDetails()">Save</v-btn>
                </v-flex>
            </v-layout>
        </app-dialog>
    </v-flex>
</template>

<script>
import moment from 'moment'
import appConstants from '@/utils/appConstants'
import PaymentMode from '@/components/PaymentMode.vue'
export default {
    components: {
        PaymentMode,
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showDialog: false,
            txAmount: 0,
            mode: 'CASH',
            defaultAmountOption: 0,
            narration: "",
            txType: 'CR',
            txDate: new Date(),
            stopAutoNarration: false
        }
    },
    mounted() {
        this.txAmount = this.payableAmount;
    },
    computed: {
        payableAmount() {
            return this.order.balance == undefined ? this.order.total : this.order.balance
        },
        autoGeneratedNarration() {
            if (!this.stopAutoNarration)
                this.narration = `Paid ${this.txAmount == this.payableAmount ? 'amount' : 'Partial Amount'} by ${this.mode} on ${moment(this.txDate).format("DD/MM/YYYY")}`
            return this.narration
        },
        transaction() {
            return {
                txDate: this.txDate,
                mode: this.mode,
                txAmount: this.txAmount,
                refNo: this.order.orderNo,
                narration: this.narration,
                txType: 'CR',
                vendorId: this.order.vendorId
            }
        },
        convertedDate: {
            get() {
                return moment(this.txDate).format("YYYY-MM-DD")
            },
            set(newValue) {
                this.txDate = moment(newValue, 'DD/MM/YYYY')
            }
        }
    },
    methods: {
        toggleDialog() {
            this.showDialog = !this.showDialog
            this.txAmount = this.payableAmount;
        },
        async addPaymentDetails() {
            this.order.paymentDetails = this.transaction
            await this.putItem(appConstants.UPDATE_PAYMENT_DETAILS + "/" + this.order._id, this.order)
            this.$emit('updatedPaymentDetails')
            this.toggleDialog()
            this.txAmount = 0
            this.mode = 'CASH'
            this.defaultAmountOption = 0
            this.narration = ""
            this.txType = 'CR'
            this.txDate = new Date()
            this.stopAutoNarration = false
        },
        updateMode(mode) {
            this.mode = mode
        },
        maxValue(event) {
            this.txAmount = event.target.value > this.payableAmount ? this.payableAmount : event.target.value
        }
    },
}
</script>

<style scoped>
.centered-input>>>input {
    text-align: center;
    font-size: 1.5rem;
}

.border-light {
    border-color: lightgray !important;
    text-transform: capitalize;
}
</style>
