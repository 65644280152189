import { render, staticRenderFns } from "./VendorPlans.vue?vue&type=template&id=32026eba&scoped=true"
import script from "./VendorPlans.vue?vue&type=script&lang=js"
export * from "./VendorPlans.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32026eba",
  null
  
)

export default component.exports