<template>
    <div class="text-left">
        <div class="mx-1" v-if="addresses.length > 0">
            <v-radio-group @change="submitAddress()" v-model="selectedAddress" label="Select Delivery Address">
                <v-radio v-for="address in addresses" :key="address" :label="address" :value="address"></v-radio>
            </v-radio-group>
        </div>
        <div class="text-center">
            <h4 class="mt-n2 text-h6" :style="themeInverted">OR</h4>
            <v-textarea class="mx-2" outlined @input="submitAddress()" label="Enter New Delivery Address"
                v-model="newAddress"></v-textarea>
        </div>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import axios from 'axios'
export default {
    data() {
        return {
            addresses: [],
            selectedAddress: '',
            newAddress: '',
            saveUserAddress: false
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            if (this.getUserProfile()._id)
                await this.getItem(appConstants.WEBSTORE_CUSTOMER_ADDRESSES.replace('$id', this.getUserProfile()._id)).then(data => {
                    this.addresses = data[0].addresses
                })
        },
        submitAddress() {
            if (!this.selectedAddress) {
                this.saveUserAddress = true
                this.$emit(this.appEvents.UPDATE, this.newAddress, this.saveUserAddress)
            } else
                this.$emit(this.appEvents.UPDATE, this.selectedAddress, this.saveUserAddress)
        }
    }
}
</script>
