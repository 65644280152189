<template>
    <div class="mt-2">
        <label class="coupon-error">{{ msg }}</label>
        <v-text-field outlined @input="$emit('input', coupon)" v-model="coupon" label="Enter coupon code" clearable
            @click:clear="$emit('clear')">
            <template v-slot:append>
                <v-btn @click="applyCoupon()" :style="theme" class="mt-n2">Apply</v-btn>
            </template>
        </v-text-field>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {
    props: ['order'],
    data() {
        return {
            coupon: '',
            discountAmount: 0
        }
    },
    methods: {
        async applyCoupon() {
            this.order.couponCode = this.coupon
            this.order.vendorId = this.$store.getters.vendor._id
            var user = this.getUserProfile()
            this.order.customer = this.convertToUserRefModel(user)
            try {
                this.msg = ''
                this.discountAmount = await this.postItem(appConstants.COUPONS_API + "/applyCoupon", this.order)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
            }
            this.$emit('setAmount', this.discountAmount)
        }
    },
}
</script>

<style scoped>
.coupon-error {
    color: red;
    font-weight: 400;
}
</style>
