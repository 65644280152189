import { render, staticRenderFns } from "./StoreCouponCard.vue?vue&type=template&id=6bff400c&scoped=true"
import script from "./StoreCouponCard.vue?vue&type=script&lang=js"
export * from "./StoreCouponCard.vue?vue&type=script&lang=js"
import style0 from "./StoreCouponCard.vue?vue&type=style&index=0&id=6bff400c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bff400c",
  null
  
)

export default component.exports