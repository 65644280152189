<template>
    <div>
        <component :is="storeDesign"></component>
    </div>
</template>

<script>
import Webstore1 from './Webstore1.vue';
import Webstore2 from './Webstore2.vue';

export default {
    computed: {
        storeDesign() {
            if (this.$store.getters?.vendor?.storeUI == this.newStoreUI)
                return Webstore2
            else
                return Webstore1
        }
    }
}
</script>
<style scoped></style>
