<template>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-title class="text-title mt-2 font-weight-bold">{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.code }}</v-list-item-subtitle>
            <v-list-item-subtitle class="body-2">
                <v-row align="center">
                    <v-col cols="3"><span class="mb-8">₹ {{ item.price }}/- x</span></v-col>
                    <v-col cols="4">
                        <v-text-field outlined label="Qty" class="mt-1" type="number" dense :full-width="false"
                            v-model="item.quantity" v-if="edit">{{
                                item.quantity }}</v-text-field>
                        <label class="text-caption" v-else>{{ item.quantity }}</label></v-col>
                </v-row>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
            <v-layout column>
                <v-flex text-right v-if="edit">
                    <v-icon small @click="$emit('deleteItem')">mdi-delete</v-icon>
                </v-flex>
                <v-flex>
                    <v-layout column>
                        <v-list-item-subtitle style="text-align:right">Amount</v-list-item-subtitle>
                        <label class="price-style">₹ {{ item.price * item.quantity | amountFormat }}/-</label>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-list-item-icon>
    </v-list-item>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
        },
        edit: {
            type: Boolean
        }
    },
}
</script>

<style scoped>
.price-style {
    font-size: 0.95rem;
}

.body-1 {
    font-size: 0.85rem !important;
    line-height: 1rem !important;
    white-space: normal
}

.body-2 {
    padding-top: 2px;
}
</style>
