<template>
    <v-btn v-if="$store.getters.vendor.whatsappNo" class="mr-4"
        :style="`margin-bottom: ${moreBottomMargin ? '100px' : '40px'}`" fab :href="urlEncodedMsg" target="_blank" fixed
        bottom right>
        <v-icon>mdi-face-agent</v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        whatasppMsg: {
            type: String,
            default: ""
        },
        moreBottomMargin: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        urlEncodedMsg() {
            return encodeURI("https://api.whatsapp.com/send?phone=91" + this.$store.getters.vendor.whatsappNo)
        }
    },
}
</script>

<style scoped>
.btn-margin {
    margin-bottom: 50px !important;
}
</style>
