<template>
    <div>
        <v-bottom-navigation app grow :style="themeInverted" color="red" :value="value" height="70">
            <v-btn icon-style @click="$router.push('/store')">
                <span>{{ storeName }}</span>
                <img v-if="$store.getters.vendor.logo" class="logo" width="35" height="35"
                    :src="host + $store.getters.vendor.logo.path">
                <v-icon v-else>mdi-handshake</v-icon>
            </v-btn>
            <v-btn class="icon-style" @click="$router.push('/store/CategoryAndProducts/searchText')">
                <span>search</span>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon-style @click="checkLogin()">
                <span>Account</span>
                <v-icon>mdi-account</v-icon>
            </v-btn>
        </v-bottom-navigation>
        <v-navigation-drawer v-if="isLoggedIn" right v-model="drawer" app temporary>
            <div class="pa-2" :style="theme">
                <v-icon class="mx-2 my-3" :style="theme">mdi-account-circle</v-icon>{{ getUserProfile().displayName }}
            </div>
            <v-list nav dense>
                <v-list-item-group active-class="deep-purple--text text--accent-4">
                    <v-list-item to="/store/customerOrder">
                        <v-list-item-icon>
                            <v-icon :style="themeInverted">mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>My Orders</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$router.push('/store/editAccount/' + getUserProfile()._id)">
                        <v-list-item-icon>
                            <v-icon :style="themeInverted">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Account Details</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/app/dashboard" v-if="isAllowed('viewApp')">
                        <v-list-item-icon>
                            <v-icon :style="themeInverted">mdi-handshake</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Vendor Dashboard</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout" v-if="isLoggedIn">
                        <v-list-item-icon>
                            <v-icon :style="themeInverted">mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            value: 0
        };
    },
    methods: {
        checkLogin() {
            if (this.isLoggedIn)
                this.drawer = !this.drawer
            else
                this.$router.push('/store/login')
        }
    },
}
</script>

<style lang="scss" scoped>
h4 {
    margin-top: 0;
    margin-bottom: 0;
}

.icon-style {
    font-weight: 600;
    font-size: 0.8rem !important;
}
</style>
