<template>
    <div class="text-left">
        <p class="title" :style="themeInverted">Select Delivery Type</p>
        <v-radio-group :row="showInline" mandatory v-model="deliveryType">
            <v-radio v-for="type in deliveryType" :key="type.code" :label="type.label ? type.label : type"
                :value="type">
            </v-radio>
        </v-radio-group>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: Array,
            default: () => []
        },
        showInline: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            deliveryTypes: this.$store.getters.vendor.delivery
        }
    },
    computed: {
        deliveryType: {
            get() {
                return this.type.length > 0 ? this.type : this.deliveryTypes
            },
            set(newValue) {
                this.$emit(this.appEvents.INPUT, newValue)
            }
        }
    },
}
</script>
