<template>
    <div class="my-8">
        <div sm3 v-if="categoryProducts.length > 0 || products.length > 0" class="bottom-border mx-6">
            <label class="categoryTitle">{{ title }}</label>
            <v-btn small text @click="showAllProducts(categoryId, title)">(View All)</v-btn>
        </div>
        <div v-if="$vuetify.breakpoint.smAndUp || categoryId">
            <v-row v-if="$vuetify.breakpoint.xsOnly">
                <v-col :cols="$store.getters.vendor.productCardMobile == 1 ? '12' : '6'">
                    <product-card-mobile :product="item"
                        v-for="(item, index) in categoryId ? categoryProducts : products"
                        :key="index"></product-card-mobile><v-divider />
                </v-col>
            </v-row>
            <horizontal-scroll v-else>
                <v-col cols="6" sm="4" md="3" lg="2" v-for="(item, index) in categoryId ? categoryProducts : products"
                    :key="index">
                    <product-card-web :item="item"></product-card-web>
                </v-col>
            </horizontal-scroll>
        </div>
        <div v-else wrap>
            <v-row wrap>
                <v-col :cols="$store.getters.vendor.productCardMobile == 2 ? '6' : '12'"
                    v-for="(item, index) in products" :key="index">
                    <span v-if="index < 5">
                        <product-card-mobile :product="item"></product-card-mobile>
                    </span>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import ProductCardWeb from '@/webstoreComponents/ProductCardWeb'
import appConstants from '@/utils/appConstants'
import HorizontalScroll from '@/webstoreComponents/HorizontalScroll.vue'
import ProductCardMobile from '@/webstoreComponents/ProductCardMobile'
import CategoryCardWithBackground from '@/webstoreComponents/CategoryCardWithBackground'
export default {
    props: {
        products: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: ''
        },
        categoryId: {
            type: String,
            default: ''
        },
        category: {
            type: Object
        }
    },
    components: {
        HorizontalScroll,
        ProductCardMobile,
        ProductCardWeb,
        CategoryCardWithBackground
    },
    data() {
        return {
            categoryProducts: []
        }
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        async initComponent() {
            if (this.categoryId) {
                var productList = await this.getItem(appConstants.WEBSTORE_PRODUCTS + "/category/" + this.categoryId)
                this.categoryProducts = productList.data
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.categoryTitle {
    font-size: 22px;
    font-weight: 500;
}

.col-6 {
    padding: 6px;
}

@media only screen and (max-width: 500px) {
    .categoryTitle {
        font-size: 18px;
        font-weight: 600;
    }

    .bottom-border {
        border-bottom: 1px solid #d4d4d4;
    }
}
</style>
