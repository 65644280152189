<template>
    <v-flex>
        <v-form>
            <alert-message v-if="msg" :error="msg"></alert-message>
            <social-media-login></social-media-login>
            <v-flex text-center>
                <h3>OR</h3>
            </v-flex>
            <v-text-field ref="username" v-model="userLogin.mobileNo" prepend-icon="mdi-account"
                placeholder="Mobile No" />
            <v-text-field v-model="userLogin.password" prepend-icon="mdi-lock" placeholder="Password" type="password"
                @keypress.enter="login()" />
            <v-btn block :style="theme" @click="login()">
                <v-icon class="pa-2">mdi-shield-account</v-icon>Login
            </v-btn>
            <v-btn block outlined class="my-4" :style="themeInverted" to="/store/resetPassword">Forgot Password</v-btn>
        </v-form>
    </v-flex>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import SocialMediaLogin from '@/components/SocialMediaLogin.vue'
export default {
    props: {
        userType: ''
    },
    components: {
        SocialMediaLogin
    },
    data() {
        return {
            userLogin: {
                mobileNo: "",
                password: ""
            },
            userToken: ''
        }
    },
    mounted() {
        this.$refs.username.focus();
    },
    methods: {
        async login() {
            try {
                this.userLogin.vendorId = this.$store.getters.vendor._id
                this.userToken = await this.postItem(appConstants.AUTHENTICATE_API, this.userLogin)
                await this.setDataInLocalStorage(this.userToken.token)
                let isVendor = JSON.parse(localStorage.getItem("userProfile")).userType == "STAFF"
                await this.updateDeviceStatus()
                if (isVendor) {
                    this.$router.push("/app/dashboard");
                } else {
                    this.navigateAfterLogin()
                }
            } catch (error) {
                console.log(error)
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        async updateDeviceStatus() {
            try {
                let deviceId = localStorage.getItem(appConstants.LS_DEVICE_ID)
                if (deviceId) {
                    await this.postItem(appConstants.WEBSTORE_UPDATE_DEVICE, { deviceId: deviceId, vendorId: this.$store.getters.vendor._id, userId: JSON.parse(localStorage.getItem('userProfile'))._id })
                }
            } catch (error) {
                this.handleError(error)
            }
        },
        upadateLocalNotifcationData(userId) {
            let localNotificationData = this.$store.getters.notification
            localNotificationData.userId = userId
            this.$store.dispatch("actionUpdateNotification", localNotificationData)
        }
    },
}
</script>

<style scoped></style>
