<template>
    <v-list-item two-line>
        <v-list-item-content>
            <v-list-item-subtitle :class="labelClass" :style="themeInverted">{{ label }}</v-list-item-subtitle>
            <v-list-item-title class="wrap-text">
                <slot>
                    <label :class="valueClass" v-html="value"></label>
                </slot>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: {
        label: String,
        value: [String, Number],
        valueClass: String,
        labelClass: String
    }
}
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
    white-space: normal;
}

.wrap-text {
    white-space: normal;
}
</style>