<template>
    <v-layout wrap>
        <v-flex sm8>
            <v-card flat>
                <v-card-text v-if="product">
                    <whatsapp-product-share :small="true" @click="buildProductMsg()"
                        :whatasppProductMsg="buildProductMsg()"></whatsapp-product-share>
                    <v-card-title :style="themeInverted" class="text-transform-capitalize ">{{ product.title
                        }}</v-card-title>
                    <video-player v-if="path && mimetype.startsWith('video/')" :src="path" height="500"
                        width="1000"></video-player>
                    <v-img v-else-if="path && mimetype.startsWith('image/')" contain :src="imagePath(path)"
                        height="400px"></v-img>
                    <v-flex text-center v-else><img height="300px" :src="defaultProductImage" /></v-flex>
                    <br>
                    <h3 v-if="product.images?.length > 0">More Images</h3>
                    <v-layout wrap>
                        <v-flex @click="selectedImage(image)" xs6 sm4 md3 class="pa-2" v-for="image in product.images"
                            :key="image?.filename" width="200">
                            <video-player v-if="image?.mimetype == 'video/mp4'" :src="image?.path" height="150"
                                width="150"></video-player>
                            <v-img v-else :src="imagePath(image?.path)" max-width="150px" :height="150"></v-img>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex sm4>
            <v-card flat>
                <v-card-text v-if="product">
                    <v-card-title :style="themeInverted">Details</v-card-title>
                    <v-simple-table>
                        <v-layout wrap>
                            <v-flex wrap>
                                <row-item label="Name : " valueClass="text-transform-capitalize "
                                    :value="product.title"></row-item>
                                <row-item v-if="product.type == 'BUNDLE'" label='Items'></row-item>
                                <div class="mt-n6" v-if="product.bType == 'FIXED'">
                                    <ul v-for="(product) in product.swapOptions" :key="product._id">
                                        <li>{{ product.title }}</li>
                                    </ul>
                                </div>
                                <div class="mt-n6" v-else-if="product.swapOptions?.length > 0">
                                    <ul>
                                        <li>{{ product.swapOptions[0].title }}</li>
                                    </ul>
                                </div>
                                <div class="mt-n6" v-if="product.products?.length > 0">
                                    <ul v-for="(product) in product.products" :key="product._id">
                                        <li>{{ product.title }}</li>
                                    </ul>
                                </div>
                                <row-item label="Description : " :value="product.desc"></row-item>
                                <row-item label="Price : " :value="product.price?.toString()"></row-item>
                                <row-item v-if="product.offerprice" label="Offer Price : "
                                    :value="product.offerprice.toString()"></row-item>
                                <row-item label="Brand : "
                                    :value="product.brand ? product.brand.displayName : ''"></row-item>
                            </v-flex>
                        </v-layout>
                        <v-flex text-center>
                            <v-layout class="pb-2" column
                                v-if="product.stock > 0 || $store.getters.vendor.negativeStock || (product.bType == packageModule.bType.ONE_TIME || product.bType == packageModule.bType.FUTURE_BUY || product.type == 'BUNDLE')">
                                <add-to-cart v-model="product" v-if="product._id"></add-to-cart>
                                <order-button v-model="product" v-if="product._id"></order-button>
                            </v-layout>
                            <v-layout class="pb-2" column v-else>
                                <v-flex text-center class="stockOut">
                                    Out Of Stock
                                </v-flex>
                            </v-layout>
                            <whatsapp-product-share :block="true" @click="buildProductMsg()"
                                :whatasppProductMsg="buildProductMsg()"></whatsapp-product-share>
                            <v-btn block class="my-2" :style="theme" text @click="$router.go(-1)">
                                Go Back
                            </v-btn>
                        </v-flex>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-flex>
        <v-container>
            <whatsapp-chat></whatsapp-chat>
        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import OrderButton from '@/components/OrderButton.vue'
import appConstants from '@/utils/appConstants'
import addToCart from '@/components/AddToCart.vue';
import WhatsappChat from '../../components/WhatsappChat.vue'
import WhatsappProductShare from '@/components/WhatsappProductShare.vue'
import RowItem from '../../components/RowItem.vue';
export default {
    components: {
        addToCart,
        OrderButton,
        WhatsappChat,
        WhatsappProductShare,
        RowItem
    },
    data() {
        return {
            id: 0,
            product: {
                id: "",
                title: "",
                desc: "",
                price: 0,
                purchasePrice: 0,
                offerprice: 0,
                brand: {
                    displayName: ''
                },
                images: [],
                swapOptions: [],
                products: []
            },
            items: [],
            path: '',
            mimetype: ''
        }
    },
    created() {
        if (performance.navigation.type == 1)
            this.updateVendorSetting(this.$route.params.slug)
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.vendorId = JSON.parse(localStorage.getItem("vendor"))._id
            var productList = []
            this.product = this.checkInCart(this.id)
            if (!this.product) {
                await this.getItem(appConstants.WEBSTORE_PRODUCTS + "/" + this.id, {
                    headers: {
                        'vendorid': this.vendorId
                    }
                }).then(response => {
                    productList = response
                })
                if (productList.length == 0) {
                    productList = await this.getItem(appConstants.WEBSTORE_PACKAGES + "/" + this.id, { headers: { 'vendorid': this.vendorId } })
                    productList.forEach(rec => {
                        this.product = this.convertToProductRef(rec)
                    })
                }
                else {
                    productList.forEach(element => {
                        if (element._id == this.id)
                            this.product = element
                    });
                }
            }
            if (this.product._id && this.product.images?.length > 0) {
                this.path = this.product.images[0]?.path
                this.mimetype = this.product.images[0]?.mimetype
            }
        },
        selectedImage(image) {
            this.path = image?.path
            this.mimetype = image?.mimetype
        },
        buildProductMsg() {
            var price = this.product.offerprice ? this.product.offerprice : this.product.price
            var brand = this.product.brand ? this.product.brand.displayName : ''
            var desc = this.product.desc ? this.product.desc : ''
            return "Hi there,\nWelcome to *" + this.$store.getters.vendor.businessName + "*.\nCheckout our product.\n\n" +
                this.product.title + "\navailable at Price ₹" + price + "\n" + brand + "\n" + desc +
                "\nAccess product details at \n" + window.location.href
        }
    }
}
</script>

<style scoped></style>
