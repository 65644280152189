<template>
    <v-flex>
        <input v-show="false" type="file" ref="fileUploadControl" class="btn" @change="handleFileUpload"
            :multiple="multiple" :accept="accept" />
        <v-btn :style="theme" @click="$refs.fileUploadControl.click()" text>
            <v-icon class="mr-2">mdi-cloud-upload</v-icon>{{ label }}
        </v-btn>
        <v-flex class="ma-3">
            <ul>
                <v-row>
                    <li v-for="(file, index) in files" :key="index">
                        <v-col sm="8">
                            <img :src="file.preview" v-if="file.type.startsWith('image/')" :alt="file.name" width="100">
                            <p>{{ file.name }}</p>
                        </v-col>
                    </li>
                </v-row>
            </ul>
        </v-flex>
    </v-flex>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: () => 'Upload Files'
        },
        multiple: {
            type: Boolean,
            default: () => false
        },
        accept: {
            type: String
        }
    },
    data() {
        return {
            files: []
        };
    },
    methods: {
        removeFile() {
            this.files = []
        },
        handleFileUpload(event) {
            const selectedFiles = Array.from(event.target.files);
            selectedFiles.forEach(file => {
                file.preview = URL.createObjectURL(file);
            });
            this.files = selectedFiles;
            this.$emit("input", this.multiple ? this.files : this.files[0]);
        }
    }
};
</script>

<style lang="scss" scoped></style>
