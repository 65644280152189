<template>
    <v-flex>
        <new-store-titlebar v-if="$vuetify.breakpoint.smAndUp"></new-store-titlebar>
        <router-view></router-view>
        <new-footer v-if="$vuetify.breakpoint.smAndUp"></new-footer>
        <bottom-navbar v-if="this.$vuetify.breakpoint.xsOnly && showBottomNav"></bottom-navbar>
    </v-flex>
</template>

<script>
import NewStoreTitlebar from '@/webstoreComponents/NewStoreTitlebar'
import BottomNavbar from '@/webstoreComponents/BottomNavbar'
import NewFooter from '@/webstoreComponents/NewStoreFooter'
export default {
    components: {
        NewStoreTitlebar,
        BottomNavbar,
        NewFooter
    },
    data() {
        return {
            showBottomNav: true
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.showBottomNav = true
        next()
    },
}
</script>
<style scoped></style>