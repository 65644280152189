<template>
    <v-row align="center">
        <v-col cols="6" sm="3">
            <v-select label="Discount type" v-model="type" :items="items" @change="resetValues()"></v-select>
        </v-col>
        <v-col cols="6" sm="3">
            <v-text-field dense v-model.number="discountAmount" type="number" class="mt-4" />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['subTotal', 'value'],
    data() {
        return {
            items: ['%', 'Amount'],
            discount: {
                type: '%',
                percent: 0,
                amount: 0
            }
        }
    },
    computed: {
        discountAmount: {
            get() {
                if (this.value.percent > 0)
                    return this.value.percent
                else
                    return this.value.amount
            },
            set(newValue) {
                if (this.type == '%') {
                    this.discount.amount = ((this.subTotal * newValue) / 100)
                    this.discount.percent = newValue
                }
                else
                    this.discount.amount = newValue
                this.$emit('input', this.discount)
            }
        },
        type: {
            get() {
                if (this.value.type)
                    return this.value.type
                else
                    return '%'
            },
            set(newValue) {
                this.discount.type = newValue
                this.$emit('input', this.discount)
            }
        }
    },
    methods: {
        resetValues() {
            this.discount.percent = 0
            this.discount.amount = 0
        }
    },
}
</script>
