<template>
    <v-app :style="themeInverted">
        <v-main>
            <enable-notifications></enable-notifications>
            <router-view />
            <router-view name="login" />
            <notifications position="bottom right" />
        </v-main>
    </v-app>
</template>

<script>
import EnableNotifications from './components/EnableNotifications.vue';
export default {
    components: { EnableNotifications },
    name: "App",
    mounted() {
        document.title = this.$store?.getters?.vendor?.businessName || 'Online Dukan'
    }
};
</script>
