<template>
    <v-flex>
        <old-store-titlebar dense></old-store-titlebar>
        <router-view></router-view>
        <app-footer></app-footer>
    </v-flex>
</template>

<script>
import OldStoreTitlebar from '@/components/OldStoreTitlebar.vue';
import AppFooter from '@/components/Footer.vue'
export default {
    components: {
        OldStoreTitlebar,
        AppFooter,
    }
}
</script>
<style scoped></style>