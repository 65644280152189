<template>
    <v-card class="d-flex flex-column mx-2 my-4 card-border" elevation='6' max-width="250" max-height="250">
        <v-card-title class="justify-center" :style="themeInverted">
            <span v-if="coupon.couponType == 'GENRAL'">
                For <b>All Users</b>
            </span>
            <span v-if="coupon.couponType == 'PRODUCT'">
                <b>{{ coupon.product.displayName }}</b>
            </span>
            <span v-if="coupon.couponType == 'BRAND'">
                <b>{{ coupon.brand.displayName }}</b>
            </span>
            <span v-if="coupon.couponType == 'CATEGORY'">
                <b>{{ coupon.category.displayName }}</b>
            </span>
        </v-card-title>
        <v-card-title :style="theme" class="justify-center coupon-value" v-if="coupon.amount">
            <h3>Rs {{ coupon.amount }}/- off</h3>
        </v-card-title>
        <v-card-title :style="theme" class="justify-center coupon-value" v-if="coupon.percent">
            <h3>{{ coupon.percent }}% off</h3>
        </v-card-title>

        <v-card-text class="text-center my-0">
            with order above <b>Rs {{ coupon.minOrderAmount }}/-</b>
        </v-card-text>
        <v-card-subtitle class="text-center">
            <label class="code-font">{{ coupon.code }}</label>
        </v-card-subtitle>
    </v-card>
</template>

<script>
export default {
    props: {
        coupon: {
            type: Object
        },
    },
    computed: {
        border() {
            return { 'border': 'solid ' + `${this.$store.getters.vendor != undefined && this.$store.getters.vendor.themeColor ? this.$store.getters.vendor.themeColor : 'orange'} !important` }
        }
    },
}
</script>

<style scoped>
.card-border {
    border-radius: 10px !important;


}

.coupon-value {
    font-size: 1.5rem !important;
}

.code-font {
    font-size: 1.2rem;
    padding: 3% 10%;
    border-style: dashed;
    border-color: lightgray;
}

.flex-display {
    display: flex;
    justify-content: center
}

@media only screen and (max-width: 500px) {
    .code-font {
        font-size: 1.1rem;
        padding: 1.5%;
    }
}

.flex-display {
    display: flex;
    justify-content: center
}

@media only screen and (max-width: 500px) {
    .code-font {
        font-size: 1.1rem;
        padding: 1.5%;
    }
}
</style>
